// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//const BASE_API_IP = 'http://182.74.129.3:8088/';
//const BASE_API_IP = 'https://localhost:44302/';
//const BASE_API_IP = 'http://pc.dradrianmd.com/';
//const BASE_API_IP = 'http://pc2.dradrianmd.com/';
const BASE_API_IP = 'https://stagingapi.mypatientinfo.com/';
//const BASE_API_IP='https://localhost:44302/';
export const environment = {
  production: false,
  isAPIMock: false,
  BASE_API_IP: BASE_API_IP,
  BASE_API_URL: BASE_API_IP + 'api/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
